.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 108%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
.error {
  background-color: lightsalmon;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid tomato;
  display: flex;
  justify-content: center;
  margin-top: 1%;
}
.iframe{
  width:99%;
  height: 96%;
  margin-top: 2%;
  
}
.errorlength {
display: flex;
justify-content: center;  
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  /* margin: 7% auto; 15% from the top and centered */
  margin-left: 13%;
  margin-top: 5%;
  padding: 1%;
  border: 1% solid #888;
  height: 80%;
  width: 63%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  margin-top: -2%;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
